import React, { useState, useRef, useEffect } from 'react';
import '../../tailwind.css';
import Close from 'remixicon-react/CloseLineIcon';
import Github from 'remixicon-react/GithubFillIcon';
import Youtube from 'remixicon-react/YoutubeFillIcon';

const Project = ({ title, description, tags, links }) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const popupRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600); 
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const togglePopup = () => {
        setPopupOpen(!popupOpen);
    };

    return (
        <div className={`border-2 border-border ml-14 mx-24 mb-9 w-[280px] h-52 rounded-3xl shadow-lg hover:bg-slate-200 ${popupOpen ? '' : 'cursor-pointer'}`} onClick={!popupOpen ? togglePopup : null}>
            <h3 className='py-5 px-5 text-lg font-bold overflow-hidden whitespace-nowrap overflow-ellipsis'>{title}</h3>
            <h4 className='px-5 text-sm text-secondary break-words line-clamp-3 pb-6'>{description}</h4>
            {!isMobile && ( 
                <div className="flex items-center pl-5">
                    <div className="flex flex-wrap gap-2">
                        {tags.map((tag, index) => (
                            <div key={index} className="inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-tec text-white shadow hover:bg-primary/80 ">
                                {tag}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {popupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-10">
                    <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
                    <div
                        id='popup'
                        ref={popupRef}
                        className={`bg-white rounded-lg pl-5 pr-2 z-20 m-h-56 border-solid border-2 border-gray-600 ${
                            isMobile ? 'w-[90%]' : 'w-[480px]'
                        }`}
                    >
                        <div className="flex justify-end mt-1">
                            <button onClick={togglePopup} className="focus:outline-none">
                                <Close className="text-gray-600" />
                            </button>
                        </div>

                        <h2 className="text-xl font-semibold mb-4 tracking-tight">{title}</h2>
                        <p className="text-sm text-secondary">{description}</p>
                        <div className='flex items-center space-x-2 mt-3'>
                            <span>Languages:</span>
                            {tags.map((tag, index) => (
                                <span key={index} className="text-sm text-gray-600">
                                    {tag}{index !== tags.length - 1 && ', '}
                                </span>
                            ))}
                        </div>
                        <div className='mt-5 flex space-x-2 mb-3'>
                            {links.github && (
                                <a href={links.github} target="_blank" rel="noopener noreferrer">
                                    <Github size={25} />
                                </a>
                            )}
                        </div>
                        <div className="flex justify-center mb-3">
                            {links.youtube && (
                                <iframe
                                    width="600"
                                    height="315"
                                    src={links.youtube}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Project;
